<template>
  <div class="content-wrapper">
    <bo-page-title />    
    <div class="content pt-0">
      <b-card no-body>
        <b-card-header>
          <b-row>
            <b-col lg="8">
              <b-form-row>
                <b-col lg="4">
                  <div class="input-group mb-3">
                    <datepicker input-class="form-control transparent" placeholder="Tanggal Awal"
                      class="my-datepicker" calendar-class="my-datepicker_calendar" v-model="filter.startDate"
                      @input="doFilterDate">
                    </datepicker>
                    <div class="input-group-append calendar-group">
                      <span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
                    </div>
                  </div>  
                </b-col>
                <b-col lg="4">
                  <div class="input-group mb-3">
                    <datepicker input-class="form-control transparent" placeholder="Tanggal Akhir"
                      class="my-datepicker" calendar-class="my-datepicker_calendar" v-model="filter.endDate"
                      @input="doFilterDate"
                      :disabledDates="{ to: new Date(filter.startDate) }">
                    </datepicker>
                    <div class="input-group-append calendar-group">
                      <span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
                    </div>
                  </div>
                </b-col>
              </b-form-row>
            </b-col>
            <b-col lg="4">
              <SearchInput :value.sync="filter.search" @search="doFilter" />
            </b-col>
          </b-row>
        </b-card-header>
        <b-card-body class="p-0">
          <table class="table table-striped">
            <thead>
              <tr>
                <th>#</th>
                <th>Aktifitas</th>
                <th>Deskripsi</th>
                <th>Tanggal</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(v,k) in data.data" :key="v.id">
                <td>{{(data.per_page*(data.current_page-1))+k+1}}</td>
                <td>{{v.aa_title}}</td>
                <td>{{v.aa_description}}</td>
                <td>{{v.aa_created_date | moment("DD MMMM YYYY, HH:mm")}} WIB</td>
              </tr>
            </tbody>
            <tbody v-if="data.data.length == 0">
              <tr>
                <td colspan="4">
                  <div class="text-center">
                    <h4 align="center"><span v-if="filter.status || filter.search || filter.startDate || filter.endDate">Hasil pencarian tidak ditemukan</span>
                    <span v-else>Belum ada data {{ pageTitle }} yang terdaftar</span></h4>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </b-card-body>
        <b-card-footer class="pagination-custom" v-if="(dataList||[]).length&&data.total>10">        
            <b-pagination
              class="mb-0"
              v-model="pageNo"
              :per-page="data.per_page"
              :total-rows="data.total"
            />
        </b-card-footer>
      </b-card>
    </div>
  </div>
</template>
<script>
  import GlobalVue from '@/libs/Global.vue'
  import Datepicker from 'vuejs-datepicker'
  const moment = require('moment')

  export default {
    extends: GlobalVue,
    components:{ Datepicker },
    methods: {
      doFilterDate(){
        this.filter.startDate = moment(this.filter.startDate).format('YYYY-MM-DD')
        this.filter.endDate = moment(this.filter.endDate).format('YYYY-MM-DD')
        if(this.filter.startDate && this.filter.endDate){
          this.apiGet()
          this.doFilter()
        }
      },
      dateDisabled(ymd, date) {
        let startDate = null
        if(this.filter.startDate){
          startDate = this.filter.startDate.split("-")
          const day = date.getDate()
          const month = date.getMonth() + 1
          const year = date.getFullYear()

          if(year <= parseInt(startDate[0])){
            return day < parseInt(startDate[2]) || month < parseInt(startDate[1])
          }
          else{
            return
          }
        }
        return true
      }
    },
    mounted() {
      this.apiGet()
    },
    watch:{
      $route(){
        this.apiGet()
      },
      '$route.params.pageSlug':function(){
        this.$set(this,'filter',{})
      }
    }
  }
</script>